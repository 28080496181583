import axios from "axios";
import { Auth } from "aws-amplify";

// axios instance for making requests
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use(async (config) => {
  // add token to request headers
  try {
    config.headers.Authorization = (
      await Auth.currentSession()
    ).idToken.jwtToken;
  } catch (e) {}
  return config;
});

export default axiosInstance;
