import Layout from "components/layout";
import ChestList from "components/chest-list";
import React, { useState } from "react";
import { TITLE_TOOLCHEST } from "utils/constants";
import "bootstrap/dist/css/bootstrap.css";
import "styles/engage-tool.css";
import { useSelector } from "react-redux";
import arrow from "assets/images/down-arrow-svgrepo-com.svg";
import useCollapse from "react-collapsed";
import { ToastContainer } from "react-toastify";

export default function ToolChest() {
  const [isOpen, setIsOpen] = useState(false);
  const [selected_view, setSelectedView] = useState("block");
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const authInfo = useSelector((state) => state.auth);

  return (
    <Layout title={TITLE_TOOLCHEST}>
      <ToastContainer />
      <navbar id="nav2">
        <a href="./dashboard">Calendar</a>&nbsp;/&nbsp;
        <a href="./tool-chest" className="active">
          <b>Tool Chest</b>
        </a>
        &nbsp;/&nbsp;
        <a href="./best-practice">Best Practices</a>
      </navbar>
      <br></br>
      <br></br>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <h3 className="linkTitle">
                <b>Tool Chest List</b>
              </h3>
              {authInfo.role !== "viewer" ? (
                isExpanded ? (
                  <img
                    src={arrow}
                    width="25"
                    className="optArrow filter-green"
                    {...getToggleProps()}
                  />
                ) : (
                  <img
                    src={arrow}
                    width="25"
                    className="optArrow filter-white"
                    {...getToggleProps()}
                  />
                )
              ) : (
                ""
              )}
            </div>
            {/* {(authInfo.role === "system_admin" || authInfo.role === "affiliate_admin") && ( */}
            <div
              className="flex flex-col justify-center p-4 rounded-md text-xl bg-blue-20 w-fit mx-auto space-y-4"
              {...getCollapseProps()}
            >
              <p
                className="addLink"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                Add Tool Chest Link
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <ChestList
              view={selected_view}
              setView={setSelectedView}
              {...{ isOpen, setIsOpen }}
            />
          </div>
        </div>

        {/*  <a href="#"  className=" md:w-full flex items-center justify-center rounded-md text-green-10 cursor-pointer  hover:text-white hover:shadow-icon  hover:duration-300 hover:ease-linear bg-customBGColor py-1 px-3"
        >
          <div className="flex justify-start md:w-full">
            <p className="text-lg md:block">+ ADD TOOL CHEST ITEM</p>
          </div>
        </a> */}
      </div>
    </Layout>
  );
}
