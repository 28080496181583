import React from "react";

export default function TextInput({ label, name, register, errors, placeholder, tabIndex }) {

  return (
    <div className="">
     
      <div className="">
        <input
        tabIndex={tabIndex}

          placeholder={placeholder}
          style={{padding:20, fontSize:20}}
          className="Box"
          {...register(name, {
            required: true,
            // pattern: /^([a-zA-Z][a-z]*[\s]{0,1}[a-z]+)+$/,
          })}
        />
        {errors[name] && (
          <p className="absolute right-100 text-sm text-red-500">
            {errors[name].message}
          </p>
        )}
      </div>
    </div>
  );
}

export function EmailInput({ label, name, register, errors, placeholder, tabIndex }) {

  return (
    <div className="">
      
      <div className="">
        <input
        tabIndex={tabIndex}
          placeholder={placeholder}
          style={{padding:20, fontSize:20}}
          className="Box"
          {...register(name, {
            required: true,
            // pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          })}
        />
        {errors[name] && (
          <p className="absolute right-100 text-sm text-red-500">
            {errors[name].message}
          </p>
        )}
      </div>
    </div>
  );
}
export function CheckboxInput({ placeholder, name, register, errors, tabIndex }) {

  return (
    <div className="flex items-center justify-end text-sm relative md:mr-4 px-10">
      <p className="pr-2 md:text-sm text-[0.8rem]">
        {placeholder}
      </p>
      <div className="w-[70%] md:w-3/4  relative group hover:text-gray-400 mt-1">
        <input
        tabIndex={tabIndex}
        id="cell"
          className="border"
          {...register(name, {
           
            
          })}
          type={"checkbox"}
        />
        <label  className="px-2" htmlFor="cell"></label>
        {errors[name] && (
          <p className="absolute right-0 text-xs text-red-500">
            {errors[name].message}
          </p>
        )}
  
      </div>
    </div>
  );
}
export function PhoneInput({ label, name, register, errors, placeholder, tabIndex }) {

  return (
    <div className="">
     
      <div className="">
        <input
        tabIndex={tabIndex}
          placeholder={placeholder}
          style={{padding:20, fontSize:20}}
          className="Box"
          {...register(name, {
            required: false,
          })}
        />
        {errors[name] && (
          <p className="absolute right-0 text-xs text-red-500">
            {errors[name].message}
          </p>
        )}
       
      </div>
    </div>
  );
}
