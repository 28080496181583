import Layout from "components/layout";
import React from "react";
import { TITLE_REGISTRATION_FORM } from "utils/constants";
import UserRequests from "components/userRequest";
import { useSelector } from "react-redux";
import ViewUsers from "pages/view-users";

export default function Dashboard() {
  const authInfo = useSelector((state) => state.auth);
  return (
    <Layout title={TITLE_REGISTRATION_FORM}>
      {/* <div className="">
        <a href=
              "/create-user"
                className="mb-2 md:w-full flex items-center justify-center rounded-md text-green-10 cursor-pointer hover:text-white hover:shadow-icon  hover:duration-300 hover:ease-linear bg-customBGColor py-1 px-3">
                <div className="flex justify-start md:w-full">

                  <p className="text-lg md:block">NEW USER</p>
                </div>
              </a>

          </div> */}

      {/* user-request */}
      <div className="space-y-8">
        {/* <navbar id="nav2">
      <a href='#' className='active'><b>User Request</b></a>&nbsp;/&nbsp;
      <a href='#'>Users</a>&nbsp;/&nbsp;
      <a href='#'>Category Request</a>
    </navbar> */}
        {(authInfo.role === "system_admin" ||
          authInfo.role === "affiliate_admin") && <UserRequests />}
        <div className="pb-8 AdminUserList">
          <ViewUsers />
        </div>
      </div>
    </Layout>
  );
}
