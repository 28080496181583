import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import close from "assets/images/close-Icon.svg";
import { requestAddCalendarEvent } from "services/calendar";
import Loading from "components/Loading";
import axios from "axios";
import { useSelector } from "react-redux";
import { fetchAffliatessAPI, fetchStateAPI } from "services/register";
import Select from "react-select";
import { notifyError, readFileArrayBuffer } from "utils/helper";
import { requestPresigned } from "services/bestpractice";
import { CloseIcon } from "assets/icons/Icons";

//schema to validate event inputs
const schema = yup
  .object({
    title: yup.string().required("Can't Be Empty"),
    start: yup.date().required("Please specify the time to start"),
  })
  .required();

// {addEventApi, error}

const AddEvents = ({ closeAddModal, getCalendarEvent }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const authInfo = useSelector((state) => state.auth);
  console.log(authInfo);

  const [dataState, setDataState] = useState([]);
  const [selectState, setSelectState] = useState("");

  const [dataAffliates, setDataAffliates] = useState([]);
  const [selectAffliates, setSelectAffliates] = useState("");

  const [listFileUpload, setListFileUpload] = useState([]);

  const [IUEvent, setIUEvent] = useState("YES");
  const [allDayChecked, setAlldayChecked] = useState(false);
  const handleSelectState = (event) => {
    console.log("🚀 ===== event", event);
    setSelectState(event);
    setSelectAffliates("");
  };
  const endDate = (date) => {
    const s =date ? new Date(date) : undefined;
    s?.setHours(23,59,59);
    
    return s;
  }
  const startDate = (date) => {
    const s = date? new Date(date) : undefined;
    s?.setHours(0,0,0);
    

    return s;
  }

  const handleSelectAffliates = (event) => {
    setSelectAffliates(event);
  };

  const getAff_pk = () => {
    const role = authInfo?.role;
    if (role === "affiliate_admin" || role === "user") return +authInfo?.aff_pk;

    if (role === "system_admin" && IUEvent === "YES") return 14429;

    if (role === "system_admin" && IUEvent === "NO")
      return +selectAffliates?.aff_pk;
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  }

  const convertDate = (date) => {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const onSubmit = async (values) => {
    setLoading(true);

    const endDate = values?.end;
    const startDate = values?.start;
    const aff_pk = getAff_pk();

    if (endDate > startDate) {
      try {
        const listURL = await Promise.all(
          listFileUpload.map(async (item) => {
            const responsePresigned = await requestPresigned({
              data: {
                object_key: item?.name,
                content_type: item?.type,
                action: "putObject",
              },
            });
            const { signedUrl } = responsePresigned.data;
            return signedUrl;
          })
        );
        const uploadUrls = [...listURL];
        const fileData = await Promise.all(
          listFileUpload.map(async (file) => {
            return await readFileArrayBuffer(file);
          })
        );

        await Promise.all(
          fileData.map(async (d, index) => {
            await axios({
              method: "put",
              url: uploadUrls[index],
              data: d,
              headers: { "Content-Type": listFileUpload[index]?.type },
            });
          })
        );
        // create event
        await requestAddCalendarEvent({
          data: {
            create: [
              {
                title: values?.title,
                description: values?.describe,
                start_date:  values?.start,
                end_date:  values?.end,
                upload_urls: listURL,
                aff_pk: aff_pk,
                allday_check: false,
              },
            ],
          },
        });
      } catch (error) {
        console.log("🚀 ===== file: index.js ===== line 54 ===== error", {
          ...error,
        });
      }
    } else {
      notifyError("End date/time should be greater than start date/time");
    }
    closeAddModal();
    await getCalendarEvent();
    setLoading(false);
  };

  const getStates = async () => {
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
  };

  const getAffliates = async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => {
            if (x.type === "C" || x.type === "R" || x.type === "L") {
              return x;
            }
          });
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([
            //{ value: "all", label: "All", state: selectAffliatesData[0].state },
            ...selectAffliatesData,
          ]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  };

  const handleSelectFile = (event) => {
    if (event.target.files[0]) {
      const notExist = listFileUpload?.every((file) => {
        return event.target.files[0].name !== file?.name;
      });

      if (notExist) {
        setListFileUpload([...listFileUpload, event.target.files[0]]);
      }
    }
  };

  const removeFile = (file) => {
    const listFiles = [];

    listFileUpload.forEach((item) => {
      if (item?.name !== file?.name) {
        listFiles.push(item);
      }
    });

    setListFileUpload(listFiles);
  };

  const toggleIUEvent = (value) => {
    setIUEvent(value);
  };

  useEffect(() => {
    getStates();
  }, [authInfo]);

  useEffect(() => {
    if (selectState) {
      getAffliates();
    }
  }, [selectState]);

  return (
    //this form is in bootstrap
    <div className="container-fluid p-4">
      <Loading isVisible={loading} />

      <div className="row">
        <div className="col-md-12 d-flex justify-content-between">
          <h3 className="linkTitle">Add Event to Calendar</h3>
          <div onClick={closeAddModal} className="cursor-pointer">
            <img src={close} width="30" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="eventForm align-content-center m-4"
          >
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Event Title
              </label>
              <input
                {...register("title")}
                type="text"
                placeholder="Title"
                className="form-control eventBox"
                id="title"
                aria-describedby="title"
              />
              <p
                className={`error text-warning position-absolute ${
                  errors.title ? "active" : ""
                }`}
              >
                {errors.title ? <i className="bi bi-info-circle me-2"></i> : ""}
                {errors.title?.message}
              </p>
            </div>
            <div className="mb-1 z-[100]">
              <label htmlFor="allDay" className="form-label">
                All Day Event
              </label>
              <input 
                {...register("allDay")} 
                type="checkbox" 
                id="allDay"
                onChange={(e) => {setAlldayChecked(e.target.checked); 
                
                  if (e.target.checked) {
                    setValue('start', startDate(getValues('start')));
                    setValue('end', endDate(getValues('end')));
                  }
                }}
                style={{ marginLeft: 7 }}
              />
            </div>
            <div className="mb-4 z-[100]">
              <label htmlFor="start" className="form-label">
                Start Date
              </label>
              {/* controllers are the way you can wrap and use datePicker inside react form-hook*/}
              {/* start date controller*/}
              <Controller
                control={control}
                name="start"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select date"
                    onChange={(date) => { allDayChecked ? field.onChange(startDate(date)) :field.onChange(date);  }}
                    selected={allDayChecked? startDate(field.value) : field.value}
                    value={allDayChecked? startDate(field.value) : field.value}
                    showTimeSelect={!allDayChecked}
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="form-control"
                    id="start"
                  />
                )}
              />
              {/* error handling */}
              <p
                className={`error text-warning position-absolute ${
                  errors.start ? "active" : ""
                }`}
              >
                {errors.start ? (
                  <i className=" bi bi-info-circle me-2"></i>
                ) : (
                  ""
                )}
                {errors.start?.message}
              </p>
            </div>
            <div className="mb-4 z-[100]">
              <label htmlFor="end" className="form-label">
                End Date
              </label>
              {/* end date controller*/}
              <Controller
                control={control}
                name="end"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select end date"
                    onChange={(date) => {  allDayChecked ? field.onChange(endDate(date)) :field.onChange(date);}}
                    selected={allDayChecked? endDate(field.value) : field.value}
                    value={allDayChecked? endDate(field.value) : field.value}
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeSelect={!allDayChecked}
                    className="form-control"
                    id="end"
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="describe" className="form-label">
                Event Description{" "}
                <span className="text-danger small">(optional)</span>
              </label>
              <input
                {...register("describe")}
                type="text"
                placeholder="describe your event"
                className="form-control"
                id="describe"
                aria-describedby="describe"
              />
            </div>

            <div className="mb-4">
              {authInfo.role === "system_admin" ? (
                <>
                  <label htmlFor="title" className="form-label">
                    IU Event
                  </label>
                  <div className="flex justify-start items-center gap-4 ml-4">
                    <div className="flex items-center">
                      <input
                        {...register("IUEvent")}
                        id="IUEventT"
                        className="accent-green-10 w-6 h-6 cursor-pointer"
                        type="radio"
                        value="YES"
                        defaultChecked
                        onChange={() => toggleIUEvent("YES")}
                      />
                      <label className="cursor-pointer pl-1" htmlFor="IUEventT">
                        YES
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        {...register("IUEvent")}
                        id="IUEventF"
                        className="accent-green-10 w-6 h-6 cursor-pointer"
                        type="radio"
                        value="NO"
                        onChange={() => toggleIUEvent("NO")}
                      />
                      <label className="cursor-pointer pl-1" htmlFor="IUEventF">
                        NO
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="mb-4">
              <Controller
                name="state"
                render={({ field }) => (
                  <Select
                    name="selectState"
                    className="selectBox"
                    {...field}
                    options={dataState}
                    onChange={handleSelectState}
                    value={
                      authInfo?.role === "affiliate_admin" ||
                      authInfo?.role === "user"
                        ? {
                            label: authInfo?.state,
                            value: authInfo?.state,
                          }
                        : selectState
                    }
                    placeholder="State"
                    style={{ fontSize: 20, padding: 20 }}
                    tabIndex={7}
                    isDisabled={IUEvent === "YES"}
                  />
                )}
                control={control}
              />
            </div>

            <div className="mb-4">
              <Controller
                name="affliates"
                render={({ field }) => (
                  <Select
                    className="selectBox"
                    {...field}
                    options={dataAffliates}
                    isDisabled={selectState.length === 0 || IUEvent === "YES"}
                    onChange={handleSelectAffliates}
                    value={
                      authInfo?.role === "affiliate_admin" ||
                      authInfo?.role === "user"
                        ? {
                            label: authInfo?.affiliate_assoc,
                            value: authInfo?.affiliate_assoc,
                          }
                        : selectAffliates
                    }
                    placeholder="Affiliate"
                    style={{ fontSize: 20, padding: 20 }}
                    tabIndex={8}
                  />
                )}
                control={control}
              />
            </div>

            {/* Upload file */}
            <div className="mb-4">
              <input type="file" onChange={handleSelectFile} />
              <div className="bg-white h-20 flex text-black-10 items-start gap-3 p-2 flex-wrap overflow-auto">
                {listFileUpload.map((file) => {
                  return (
                    <div
                      key={file?.name}
                      className="bg-slate-300 rounded-sm flex"
                    >
                      <p className="m-0 px-2"> {file.name}</p>
                      <div
                        className="cursor-pointer"
                        onClick={() => removeFile(file)}
                      >
                        <CloseIcon size={24} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-content-end">
              <button
                type="submit"
                className="px-3 rounded-md py-2 bg-green-600"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEvents;
