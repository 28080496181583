import { useState } from "react";
import { deleteLink } from "services/toolChest";
import { DeleteIcon } from "assets/icons/Icons";
import { useSelector } from "react-redux";
import { notifySuccess, notifyError } from "utils/helper";
import toolimg from "assets/images/tools.svg";
import ChestList from ".";

const confirmation_modal_data = {
  delete: {
    confirmBtnTxt: "Delete",
    msg: "Are you sure, you want to delete this toolchest?",
  },
};

export const ConfirmationModal = ({
  closeModal,
  modalType,
  confirmClicked,
  loading,
}) => {
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {confirmation_modal_data[modalType].confirmBtnTxt} this link
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {confirmation_modal_data[modalType].msg}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={confirmClicked}
              type="button"
              disabled={loading}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {loading
                ? "Loading..."
                : confirmation_modal_data[modalType].confirmBtnTxt}
            </button>
            <button
              onClick={closeModal}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default function BlockView({ chestItems, refreshList, isDeletable }) {
  const [confirmationModalType, setConfirmationModalType] = useState();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedItemRole, setSelectedItemRole] = useState(false);
  const [selectedTimestamp, setSelectedTimestamp] = useState();
  const [loading, setLoading] = useState(false);
  const authInfo = useSelector((state) => state.auth);

  const deleteToolChest = (affPk_timestamp, role) => {
    setSelectedTimestamp(affPk_timestamp);
    setSelectedItemRole(role);
    setConfirmationModalType("delete");
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const confirmClicked = async () => {
    setLoading(true);
    if (confirmationModalType === "delete") {
      const request_data = {
        delete: [
          {
            role: selectedItemRole,
            affPk_timestamp: selectedTimestamp,
          },
        ],
      };
      const response = await deleteLink(request_data);
      console.log(response);
      if (response.status === 200) {
        notifySuccess("Item deleted");
        setConfirmationModalOpen(false);
        refreshList();
      }
    }
  };

  return (
    <ul className="md:grid-flow-row sm:grid-flow-row lg:grid-cols-2 xl:grid-cols-3 grid gap-4 min-w-fit max-w-fit ml-auto mr-auto">
      {chestItems.map((chestItem) => {
        console.log(chestItem);
        return (
          <li key={chestItem?.uuid} className="p-1 shadow-2xl" style={{border: "solid" + (chestItem.aff_pk === "14429" ? "#00a94f" : "#eeeeee"), borderRadius: "10px", borderWidth: "medium"}}>
            <a key={chestItem.affPk_timestamp} />

            <div
              type="button"
              className="lg:ml-auto sm:grid-flow-row grid grid-cols-auto gap-4 min-w-fit lg:justify-end float-right"
            >
              {isDeletable(chestItem) && (
                <DeleteIcon
                  type="button"
                  className="hover:border-solid text-white hover: font-bold py-2 px-4 rounded-lg w-fit mr-3 mb-4"
                  onClick={() =>
                    deleteToolChest(chestItem.affPk_timestamp, chestItem.role)
                  }
                ></DeleteIcon>
              )}
            </div>

            <div className="max-w-sm overflow-hidden rounded-3xl">
              <div className="flex justify-content-center">
                <h4>{chestItem.title}</h4>
              </div>
              <div className="px-6 py-4" style={{height: "250px"}}>
                {Object.keys(chestItem.metadata_info).length === 0 ? (
                  <p className="flex text-white text-base justify-center">
                    {chestItem.description? chestItem.description : chestItem.short_name}
                  </p>
                ) : (
                  <p className="text-white text-base">
                    {chestItem.metadata_info && chestItem.metadata_info.description ? chestItem.metadata_info.description : chestItem.description ? chestItem.description: ""}
                  </p>
                )}
              </div>
                  <div>
              <a
                href={chestItem.url}
                target="_blank"
                className="px-6 pt-4 pb-2 flex justify-center"
                rel="noreferrer"
              >
                <button className="finline-block bg-green-10 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
                  ClICK HERE
                </button>
              </a>
              </div>
            </div>
          </li>
        );
      })}

      {isConfirmationModalOpen && confirmationModalType && (
        <ConfirmationModal
          type={confirmationModalType}
          closeModal={closeConfirmationModal}
          modalType={confirmationModalType}
          confirmClicked={confirmClicked}
          loading={loading}
        />
      )}
    </ul>
  );
}
