/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { notifySuccess, notifyError } from "utils/helper";
import { requestAddChestLink } from "services/toolChest";
import { tool_chest_request } from "utils/constants";
import "bootstrap/dist/css/bootstrap.css";

export default function Example({ setOpen, open, refreshList }) {
  const [loading, setLoading] = useState(false);
  const [toolChestLink, setToolChestLink] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const cancelButtonRef = useRef(null);

  /*const getListPending = async () => {
    try {
      const list = [];
      setLoading(true);
      const response = await getPendingRequestAPI();

      if (response && response.data) {
        for (const property in response.data) {
          if (!Array.isArray(response.data[property]))
            list.push(response.data[property]);
        }
        setListPending(list);
      }

      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
      setIsRequest(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };*/

  const createToolChestLink = async () => {
    setLoading(true);
    try {
      //const request_data = {request: toolChestLink}
      const request_data = {
        create: [
          {
            url: toolChestLink,
            title: title,
            description: description,
          },
        ],
      };
      const response = await requestAddChestLink(request_data);
      if (response.status === 200) {
        notifySuccess(tool_chest_request.SUCCESS_MESSAGE);
        //getListPending();
        refreshList();
      }
      setLoading(false);
      setOpen(false);
    } catch (error) {
      notifyError(tool_chest_request.ERROR_MESSAGE);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="linkSetting">
                  <Dialog.Title
                    as="h3"
                    className="toolLinkTitle d-flex justify-content-center"
                  >
                    Add Link
                  </Dialog.Title>
                  <br />
                  <div className="toolLink">
                    <input
                      id="title"
                      onChange={(e) => setTitle(e.target.value)}
                      className="w-full mb-4 text-black-10 py-2 shadow-sm"
                      placeholder="Add title"
                    ></input>
                    <input
                      id="link"
                      onChange={(e) => setToolChestLink(e.target.value)}
                      className="w-full mb-4 text-black-10 py-2 shadow-sm"
                      placeholder="Add link..."
                    ></input>

                    <textarea
                      id="description"
                      onChange={(e) => setDescription(e.target.value)}
                      className="textBoxArea shadow-sm"
                      placeholder="Add description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="AddLinkButton save"
                  onClick={createToolChestLink}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Save"}
                </button>

                <button
                  type="button"
                  className="AddLinkButton cancel"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
