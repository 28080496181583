import React from "react";
import { requestCategory } from "services/bestpractice";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import useCollapse from "react-collapsed";
import arrow from "assets/images/down-arrow-svgrepo-com.svg";
import { useDispatch } from "react-redux";
import previousPageAction from "redux/actions/previousPageAction";
import { useSelector } from "react-redux";

export default function BestPracticeForm() {
  const dispatch = useDispatch();

  const [categoryItem, setcategoryItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const authInfo = useSelector((state) => state.auth);

  //console.log(authInfo);

  const fetchList = async () => {
    setLoading(true);
    const response = await requestCategory();
    if (response.status === 200) {
      setcategoryItem(response.data.data);
      console.log(response);
    }
    setLoading(false);
  };

  const setPreviousURL = () => {
    dispatch(previousPageAction.addPreviousURL("/best-practice"));
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div className="container">
      <Loading isVisible={loading} />

      <div className="row">
      {(authInfo.role !== "viewer") ? (<>
        <div
          className="col-md-12 d-flex justify-content-center"
          {...getToggleProps()}
        >
          <h3 className="linkTitle">
            <b>Best Practice Categories</b>
          </h3>
          {isExpanded ? (
            <img src={arrow} width="25" className="optArrow filter-green" />
          ) : (
            <img src={arrow} width="25" className="optArrow filter-white" />
          )}
        </div>
        <div
          className="ml-4 -translate-y-2 cursor-pointer"
          {...getCollapseProps()}
        >
          <div className="flex flex-col justify-center p-4 rounded-md text-xl bg-blue-20 w-fit mx-auto space-y-4">
            { authInfo.role === "system_admin" || authInfo.role === "affiliate_admin" ? <>
            <Link
              to="/best-practice/add-category"
              className="text-white no-underline"
            >
              Create New Category
            </Link>
            <div className="w-full h-1 bg-white"></div></> : ""}
            <Link
              to="/best-practice/add-article"
              className="text-white no-underline"
              onClick={setPreviousURL}
            >
              Create New Article
            </Link>
          </div>
        </div></>) : (
          <div className="col-md-12 d-flex justify-content-center">
          <h3 className="linkTitle">
            <b>Best Practice Categories</b>
          </h3>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          <div className="categoryMainBox">
            {categoryItem &&
              categoryItem.map((el, index) => (
                <Link
                  key={index}
                  className="inline-block w-[200px] rounded-lg p-2 bg-white m-5 text-center text-black-10 font-bold no-underline"
                  to={`/best-practice/${el.uuid}`}
                  state={{ title: el?.title, categoryId: el?.uuid }}
                >
                  <div className="flex justify-center items-center gap-2">
                    {el.title}
                    {el?.aff_pk === 14429 && (
                      <div className="w-2 h-2 rounded-full bg-green-10" />
                    )}
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
