import React, { useState } from "react";
import { DisableButton, GreenButton } from "components/Button";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import authActions from "redux/actions/auth";
import { schemaSignIn } from "utils/schema";
import { notifySuccess, notifyError } from "utils/helper";
import { LOGIN_SUCCESS_MESSAGE } from "utils/constants";

export default function Form({ userData, onAuthCodeChange, onResend }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [authErr, setAuthErr] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSignIn),
  });

  const onResendClicked = async () => {
    setLoadingResend(true);
    const result = await onResend({
      username: userData.username,
      password: userData.password,
    });
    setLoadingResend(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // If MFA is enabled, sign-in should be confirmed with the confirmation code
    try {
      const response = await Auth.sendCustomChallengeAnswer(
        userData.user, // Return object from Auth.signIn()
        userData.code // Confirmation code
      );
      try {
        //const response = await Auth.signIn(userName, password);
        const setUserSession = await Auth.currentSession();

        const role = setUserSession.getIdToken().payload.role;
        const aff_pk = setUserSession.getIdToken().payload.aff_pk;
        const state = setUserSession.getIdToken().payload?.state;
        const affiliate_assoc =
          setUserSession.getIdToken().payload?.affiliate_assoc;

        dispatch(
          await authActions.loginSuccess({
            isLoggedIn: true,
            email: response?.attributes?.email,
            name: response?.attributes?.name,
            role: role,
            aff_pk: aff_pk,
            state: state,
            affiliate_assoc: affiliate_assoc,
          })
        );

        notifySuccess(LOGIN_SUCCESS_MESSAGE);
        setLoading(false);
      } catch (err) {
        notifyError(err.message);
        setAuthErr(true);
        setLoading(false);
        console.log(err);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      notifyError(err.message);
      setAuthErr(true);
      setLoading(false);
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          <h3>
            <b>AUTHENTICATE YOUR ACCOUNT</b>
          </h3>
        </div>
        <div className="text-center text-white p-5">
          Please confirm your account by entering the authorization code sent to
          your email.
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          {/* Authorization Code form */}
          <form onSubmit={onSubmit}>
            <div>
              <input
                className="Box"
                name="auth_code"
                type="text"
                onChange={(e) => {
                  setAuthErr(false);
                  onAuthCodeChange(e);
                }}
                placeholder="Authorization Code"
              />
              {authErr && (
                <div className="errMsg d-flex justify-content-center">
                  <b>Invalid authentication code.</b>
                </div>
              )}
            </div>
            <br></br>
            <div className="d-flex justify-center">
              {loading ? (
                <DisableButton text="SUBMIT" />
              ) : (
                <GreenButton text="SUBMIT" />
              )}
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <div id="authInfo">
              It may take a minute to receive the authorization code.<br></br>If
              you haven't received the authorization code,{" "}
              <a href="#" className="text-white" onClick={loadingResend ? () => {} : onResendClicked}>
                <u>{loadingResend ? "loading..." : "resend the code"}</u>
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
