import axios from "services/setupAxios";
import {
  BEST_PRACTICE_CATEGORY,
  BEST_PRACTICE_CATEGORY_DETAIL,
  MANAGE_BEST_PRACTICES,
  PRESIGNED,
  CREATE_CATEGORY,
  ALL_ARTICLES,
} from "utils/request";

export const requestCategory = () => {
  return axios.get(BEST_PRACTICE_CATEGORY, {});
};

export const requestArticles = () => {
  return axios.get(ALL_ARTICLES, {});
};

export const requestCategoryID = ({ params }) => {
  return axios.get(BEST_PRACTICE_CATEGORY_DETAIL, { params });
};

export const requestAddArticle = ({ data }) => {
  return axios.post(MANAGE_BEST_PRACTICES, data);
};

export const requestPresigned = ({ data }) => {
  return axios.post(PRESIGNED, data);
};

export const requestAddCategory = ({ data }) => {
  return axios.post(CREATE_CATEGORY, data);
};
