import React, { useState, Component } from "react";
import { ConfirmationModal, UserInfoUpdateModal } from "./index";
import "bootstrap/dist/css/bootstrap.css";
import "styles/engage-tool.css";
import UserItem from "./UserItem";
import { PlusIcon } from "assets/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import UserDeleteActions from "redux/actions/userDelete";
import { updateUserInfo } from "services/userUpdate";
import { fetchAffliatessAPI, fetchStateAPI } from "services/register";
import { useEffect } from "react";

export default function BlockView({
  userItems,
  userModalType,
  setUserModalType,
  isUserModalOpen,
  setUserModalOpen,
  modalType,
  setModalType,
  selectedUserEmail,
  setSelectedUserEmail,
  actionLoading,
  setActionLoading,
  fieldValue,
  updateFieldValue,
  updateUserRole,
  changePassword,
  deleteUser,
  deactivateUserAccount,
  activateUserAccount,
  closeConfirmationModal,
  confirmClicked,
  isDeleteUsers,
  setLoading,
  fetchList,
  dataUserSelected,
  handleChangeAffiliate,
  dataState,
  setDataState,
  selectState,
  setSelectState,
  dataAffliates,
  setDataAffliates,
  selectAffliates,
  setSelectAffliates
}) {
  const dispatch = useDispatch();

  const authInfo = useSelector((state) => state.auth);

  const listUserDelete = useSelector(
    (state) => state.userDelete.listUserDelete
  );

  const handleSelectUserDelete = (event, userItem) => {
    if (event.target.checked) {
      dispatch(UserDeleteActions.addUserDelete(userItem));
    } else {
      dispatch(UserDeleteActions.removeUserDelete(userItem));
    }
  };

  const handleDeleteUserSelected = async () => {
    setLoading(true);
    const promises = listUserDelete.map((user) =>
      updateUserInfo({ cancel: [{ email: user.email }] })
    );

    await Promise.all(promises)
      .then((values) => {
        fetchList();
      })
      .catch((error) => {
        console.error(error.message);
      });

    dispatch(UserDeleteActions.clearUserDelete());
    setLoading(false);
  };

  const getStates = async () => {
    setLoading(true);
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const getAffliates = async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => {
            if (x.type === "C" || x.type === "R" || x.type === "L") {
              return x;
            }
          });
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([...selectAffliatesData]);
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleSelectState = (event) => {
    setSelectState(event);
    setSelectAffliates("");
  };

  const handleSelectAffliates = (event) => {
    setSelectAffliates(event);
  };

  useEffect(() => {
    if (dataUserSelected?.state && dataUserSelected?.affiliate_assoc) {
      setSelectState({
        label: dataUserSelected?.state,
        value: dataUserSelected?.state,
      });
      setSelectAffliates({
        label: dataUserSelected?.affiliate_assoc,
        value: dataUserSelected?.affiliate_assoc,
      });
    } else {
      setSelectState("");
      setSelectAffliates("");
    }
  }, [dataUserSelected, setSelectAffliates, setSelectState]);

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (selectState) {
      getAffliates();
    }
  }, [selectState]);

  return (
    <ul className="space-y-5 p-0 pb-4">
      {isDeleteUsers && (
        <button
          className="userOpt bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md"
          onClick={handleDeleteUserSelected}
          disabled={listUserDelete.length < 1}
        >
          Delete users
        </button>
      )}
      {userItems.map((userItem, index) => {
        return (
          <div key={index} className="flex items-center gap-2">
            <input
              className={`${!isDeleteUsers && "hidden"}`}
              type="checkbox"
              onChange={(event) => handleSelectUserDelete(event, userItem)}
            />

            <UserItem
              userItem={userItem}
              changePassword={changePassword}
              updateUserRole={updateUserRole}
              updateFieldValue={updateFieldValue}
              deleteUser={deleteUser}
              isDeleteUsers={isDeleteUsers}
              handleChangeAffiliate={handleChangeAffiliate}
            />
          </div>
        );
      })}

      {isUserModalOpen && userModalType && modalType === "confirmation" && (
        <ConfirmationModal
          closeModal={closeConfirmationModal}
          modalType={userModalType}
          confirmClicked={confirmClicked}
          loading={actionLoading}
        />
      )}
      {isUserModalOpen && userModalType && modalType === "value_based" && (
        <UserInfoUpdateModal
          closeModal={closeConfirmationModal}
          modalType={userModalType}
          confirmClicked={confirmClicked}
          loading={actionLoading}
          updateFieldValue={updateFieldValue}
          fieldValue={fieldValue}
          selectState={selectState}
          selectAffliates={selectAffliates}
          dataState={dataState}
          dataAffliates={dataAffliates}
          handleSelectState={handleSelectState}
          handleSelectAffliates={handleSelectAffliates}
        />
      )}

      <li className="flex">
        <a
          href={authInfo.role === "affiliate_admin" ? "/affiliate-admin/create-user" : "/admin/create-user"}
          className="hover:border-blue-500 hover:border-solid hover:bg-white hover:text-blue-500 group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium py-3"
        >
          <PlusIcon />
          Add New User
        </a>
      </li>
    </ul>
  );
}
