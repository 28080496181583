import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import Select from "react-select";
import close from "assets/images/close-Icon.svg";
import { requestAddCalendarEvent } from "services/calendar";
import Loading from "components/Loading";
import DatePicker from "react-datepicker";
import { notifyError, readFileArrayBuffer } from "utils/helper";
import {
  fetchAffliatessAPI,
  fetchStateAPI,
  getAffiliateInfo,
} from "services/register";
import { requestPresigned } from "services/bestpractice";
import axios from "axios";
import { IconDownload } from "@aws-amplify/ui-react";
import { CloseIcon } from "assets/icons/Icons";
import { useSelector } from "react-redux";

const schema = yup
  .object({
    title: yup.string().required("Can't Be Empty"),
  })
  .required();

const UpdateEvent = ({ closeModal, data, getCalendarEvent }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const authInfo = useSelector((state) => state.auth);

  const [dataState, setDataState] = useState([]);
  const [selectState, setSelectState] = useState("");

  const [dataAffliates, setDataAffliates] = useState([]);
  const [selectAffliates, setSelectAffliates] = useState("");

  const [listFileUpload, setListFileUpload] = useState([]);

  const [IUEvent, setIUEvent] = useState(
    data?.aff_pk === "14429" ? "YES" : "NO"
  );

  const toggleIUEvent = (value) => {
    setIUEvent(value);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const endDate = values?.end;
    const startDate = values?.start;
    const aff_pk =
      authInfo?.role === "affiliate_admin"
        ? +authInfo?.aff_pk
        : IUEvent === "YES"
        ? 14429
        : selectAffliates?.aff_pk;

    if (endDate > startDate) {
      try {
        const listURL = await Promise.all(
          listFileUpload.map(async (item) => {
            const responsePresigned = await requestPresigned({
              data: {
                object_key: item?.name,
                content_type: item?.type,
                action: "putObject",
              },
            });
            const { signedUrl } = responsePresigned.data;
            return signedUrl;
          })
        );
        const uploadUrls = [...listURL];
        const fileData = await Promise.all(
          listFileUpload.map(async (file) => {
            return await readFileArrayBuffer(file);
          })
        );

        await Promise.all(
          fileData.map(async (d, index) => {
            await axios({
              method: "put",
              url: uploadUrls[index],
              data: d,
              headers: { "Content-Type": listFileUpload[index]?.type },
            });
          })
        );

        await requestAddCalendarEvent({
          data: {
            update: [
              {
                uuid: data?.uuid,
                title: values?.title,
                description: values?.describe,
                start_date: values?.start,
                end_date: values?.end,
                owner: data?.owner,
                timestamp: data?.timestamp,
                upload_urls:
                  listFileUpload?.length > 0 ? listURL : data?.upload_urls,
                aff_pk: aff_pk,
              },
            ],
          },
        });

        closeModal();
      } catch (error) {
        const errorMessage = error?.response?.data?.error?.message;

        notifyError(
          errorMessage ? errorMessage : `Data Fetch Error ${error.status}`
        );

        setLoading(false);
      }
    } else {
      notifyError("End date/time should be greater than start date/time");
      setLoading(false);
      return;
    }

    await getCalendarEvent();
    setLoading(false);
  };

  const onDeleteEvent = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await requestAddCalendarEvent({
        data: {
          delete: [
            {
              uuid: data?.uuid,
            },
          ],
        },
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );

      setLoading(false);
    }
    getCalendarEvent();
  };

  const handleSelectFile = (event) => {
    if (event.target.files[0]) {
      const nameFile = event.target.files[0].name;
      const typeFile = nameFile.split(".")[nameFile.split(".").length - 1];

      if (typeFile === "esp") return;

      const notExist = listFileUpload?.every((file) => {
        return nameFile !== file?.name;
      });

      if (notExist) {
        setListFileUpload([...listFileUpload, event.target.files[0]]);
      }
    }
  };

  const handleSelectState = (event) => {
    setSelectState(event);
    setSelectAffliates("");
  };

  const handleSelectAffliates = (event) => {
    setSelectAffliates(event);
  };

  const getAffiliateByAff_pk = async () => {
    if (data?.aff_pk === "14429") return;
    try {
      const response = await getAffiliateInfo({
        params: { aff_pk: data?.aff_pk },
      });

      setSelectState({
        label: response?.data?.state,
        value: response?.data?.state,
      });
      setSelectAffliates({
        label: response?.data?.affiliateAssoc,
        value: response?.data?.affiliateAssoc,
      });
    } catch (error) {
      console.log("🚀 ===== error", error);
    }
  };

  const getStates = async () => {
    setLoading(true);
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
    setLoading(false);
  };

  const getAffliates = async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => {
            if (x.type === "C" || x.type === "R" || x.type === "L") {
              return x;
            }
          });
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([...selectAffliatesData]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  };

  const removeFile = (file) => {
    const listFiles = [];

    listFileUpload.forEach((item) => {
      if (item?.name !== file?.name) {
        listFiles.push(item);
      }
    });

    setListFileUpload(listFiles);
  };

  useEffect(() => {
    setValue("title", data?.title);
    setValue("describe", data?.description);
    setValue("start", new Date(data?.start_date));
    setValue("end", new Date(data?.end_date));
    setValue("IUEvent", IUEvent);
    getStates();
    getAffiliateByAff_pk();
  }, []);

  useEffect(() => {
    if (selectState) {
      getAffliates();
    }
  }, [selectState]);

  return (
    //this form is in bootstrap
    <div className="absolute inset-0 z-50 bg-white/30 p-10 flex items-center justify-center">
      <Loading isVisible={loading} />
      <div className="h-[90%] bg-blue-20 rounded-lg flex-1 max-w-2xl overflow-auto">
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              {(data.aff_pk === "14429" && authInfo.role !== "system_admin") ? <h3 className="linkTitle">View Calendar Event</h3> : <h3 className="linkTitle">Update Calendar Event</h3>}
              <div onClick={closeModal} className="cursor-pointer">
                <img src={close} width="30" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="eventForm align-content-center m-4"
              >
                <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                    Event Title
                  </label>
                  <input
                    {...register("title")}
                    type="text"
                    placeholder="Title"
                    className="form-control eventBox"
                    id="title"
                    aria-describedby="title"
                  />
                  <p
                    className={`error text-warning position-absolute ${
                      errors.title ? "active" : ""
                    }`}
                  >
                    {errors.title ? (
                      <i className="bi bi-info-circle me-2"></i>
                    ) : (
                      ""
                    )}
                    {errors.title?.message}
                  </p>
                </div>

                <div className="mb-4" style={{ zIndex: "100" }}>
                  <label htmlFor="start" className="form-label">
                    Start Date
                  </label>
                  {/* controllers are the way you can wrap and use datePicker inside react form-hook*/}
                  {/* start date controller*/}
                  <Controller
                    control={control}
                    name="start"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Select date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        value={field.value}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="form-control"
                        id="start"
                      />
                    )}
                  />
                  {/* error handling */}
                  <p
                    className={`error text-warning position-absolute ${
                      errors.start ? "active" : ""
                    }`}
                  >
                    {errors.start ? (
                      <i className=" bi bi-info-circle me-2"></i>
                    ) : (
                      ""
                    )}
                    {errors.start?.message}
                  </p>
                </div>
                <div className="mb-4" style={{ zIndex: "100" }}>
                  <label htmlFor="end" className="form-label">
                    End Date
                  </label>
                  {/* end date controller*/}
                  <Controller
                    control={control}
                    name="end"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Select end date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        value={field.value}
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        className="form-control"
                        id="end"
                      />
                    )}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="describe" className="form-label">
                    Event Description{" "}
                    <span className="text-danger small">(optional)</span>
                  </label>
                  <input
                    {...register("describe")}
                    type="text"
                    placeholder="describe your event"
                    className="form-control"
                    id="describe"
                    aria-describedby="describe"
                  />
                </div>
                {authInfo.role === "system_admin" && (
                  <div className="mb-4">
                    <label htmlFor="title" className="form-label">
                      IU Event
                    </label>
                    <div className="flex justify-start items-center gap-4 ml-4">
                      <div className="flex items-center">
                        <input
                          {...register("IUEvent")}
                          id="IUEventT"
                          className="accent-green-10 w-6 h-6 cursor-pointer"
                          type="radio"
                          value="YES"
                          onChange={() => toggleIUEvent("YES")}
                        />
                        <label
                          className="cursor-pointer pl-1"
                          htmlFor="IUEventT"
                        >
                          YES
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          {...register("IUEvent")}
                          id="IUEventF"
                          className="accent-green-10 w-6 h-6 cursor-pointer"
                          type="radio"
                          value="NO"
                          onChange={() => toggleIUEvent("NO")}
                        />
                        <label
                          className="cursor-pointer pl-1"
                          htmlFor="IUEventF"
                        >
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {data.aff_pk === "14429" && authInfo.role !== "system_admin" ? "" :
                <><div className="mb-4">
                  <Controller
                    name="state"
                    render={({ field }) => (
                      <Select
                        name="selectState"
                        className="selectBox"
                        {...field}
                        options={dataState}
                        onChange={handleSelectState}
                        value={
                          authInfo?.role === "affiliate_admin"
                            ? {
                                label: authInfo?.state,
                                value: authInfo?.state,
                              }
                            : selectState
                        }
                        placeholder="State"
                        style={{ fontSize: 20, padding: 20 }}
                        tabIndex={7}
                        isDisabled={
                          IUEvent === "YES" || authInfo?.role !== "system_admin"
                        }
                      />
                    )}
                    control={control}
                  />
                </div>

                <div className="mb-4">
                  <Controller
                    name="affliates"
                    render={({ field }) => (
                      <Select
                        className="selectBox"
                        {...field}
                        options={dataAffliates}
                        isDisabled={
                          selectState.length === 0 ||
                          IUEvent === "YES" ||
                          authInfo?.role !== "system_admin"
                        }
                        onChange={handleSelectAffliates}
                        value={
                          authInfo?.role === "affiliate_admin"
                            ? {
                                label: authInfo?.affiliate_assoc,
                                value: authInfo?.affiliate_assoc,
                              }
                            : selectAffliates
                        }
                        placeholder="Affiliate"
                        style={{ fontSize: 20, padding: 20 }}
                        tabIndex={8}
                      />
                    )}
                    control={control}
                  />
                </div>
                </>}

                {/* Upload file */}
                <div className="mb-4 flex justify-between gap-4">
                  <div className="mb-4 flex-1">
                    <input type="file" onChange={handleSelectFile} />
                    <div className="bg-white h-20 flex text-black-10 items-start gap-3 p-2 flex-wrap overflow-auto">
                      {listFileUpload.map((file) => {
                        return (
                          <div
                            key={file?.name}
                            className="bg-slate-300 rounded-sm flex"
                          >
                            <p className="m-0 px-2"> {file.name}</p>
                            <div
                              className="cursor-pointer"
                              onClick={() => removeFile(file)}
                            >
                              <CloseIcon size={24} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {data?.upload_urls?.length > 0 && (
                    <div className="max-w-1/2">
                      <p className="mb-1">Download file</p>
                      <div className="flex gap-1 flex-wrap">
                        {data?.upload_urls?.map((url) => {
                          return (
                            <div className="bg-green-10 w-fit rounded-full group relative">
                              <a
                                href={url}
                                className=" text-white cursor-pointer"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="p-2">
                                  <IconDownload />
                                </div>
                              </a>
                              <p class="group-hover:block hidden absolute m-0 text-white -bottom-10 w-fit bg-green-10 z-50 whitespace-nowrap p-1 rounded-md">
                                {decodeURIComponent(
                                  url
                                    ?.split("/")
                                    ?.[url?.split("/")?.length - 1]?.split(
                                      "?"
                                    )[0]
                                )}
                              </p>
                              <div className="bg-green-10 p-2 absolute -bottom-5 right-3 rotate-45 hidden group-hover:block"></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                
                {(data.aff_pk !== "14429" && authInfo.role !== "system_admin") || (authInfo.role === "system_admin") ? 
                <div className="flex gap-4">
                  <button
                    type="submit"
                    className="px-3 rounded-md py-2 bg-green-600"
                  >
                    Update
                  </button>
                  <button
                    className="px-3 rounded-md py-2 bg-red-600"
                    onClick={onDeleteEvent}
                  >
                    Delete
                  </button>
                </div>
                :
                ""}
              </form>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default UpdateEvent;
