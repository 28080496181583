import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import TextInput, { EmailInput, PhoneInput } from "./TextInput";
import { DisableButton, GreenButton, RedButton } from "components/Button";
import "./_register.css"
import {
  fetchAffliatessAPI,
  fetchStateAPI,
  reigsterUserData,
} from "services/register";


import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { schemaSignUp } from "utils/schema";
import { ToastContainer } from "react-toastify";
import { notifySuccess, notifyError } from "utils/helper";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  VALIDATE_ERROR_MESSAGE,
} from "utils/constants";
import Honeypot from "components/Honeypot";
import 'bootstrap/dist/css/bootstrap.css';
import { Stack } from '@mui/material';

export default function Form() {
  let navigate = useNavigate();
  const [dataState, setDataState] = useState([]);
  const [dataAffliates, setDataAffliates] = useState([]);

  const [listSelect, setListSelect] = useState([]);

  const [phoneCheck, setPhoneCheck] = useState(false);

  const [selectState, setSelectState] = useState("");
  const [selectAffliates, setSelectAffliates] = useState("");
  const [loading, setLoading] = useState(false);

  const [isSpam, setIsSpam] = useState(false);

  const [selectUnion, setSelectUnion] = useState(false);



  const {
    control,
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSignUp),
  });

  const addSelectData = (data) => {
    if (data.key && data.state) {
      if (!listSelect.find((el) => el.key && el.state === data.state)) {
        const filteredResult = listSelect.filter(
          (el) => el.state !== data.state
        );
        setListSelect([...filteredResult, data]);
      }
    } else {
      if (
        !listSelect.find(
          (el) =>
            el.aff_pk === data.parent ||
            el.aff_pk === data.aff_pk ||
            (el.key && el.state === data.state)
        )
      ) {
        const filteredresult = listSelect.filter(
          (el) => el.parent !== data.aff_pk
        );

        setListSelect([...filteredresult, data]);
      }
    }
  };
  const getStates = async () => {
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
  };

  const getAffliates = async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([
          ]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => {
            if (x.type === 'C' || x.type === 'R' || x.type === "L") {
              return x
            }
          });
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([
            //{ value: "all", label: "All", state: selectAffliatesData[0].state },
            ...selectAffliatesData,
          ]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  };
  const handleSelectState = (event) => {
    setSelectState(event);
    setSelectAffliates("");
  };

  const handleSelectAffliates = (event) => {

    if (event.value === "all") {

      addSelectData({
        state: selectState.value,
        shortName: selectAffliates.short_name,
        subLabel: "",
        key: "all",
      });
      setSelectAffliates(event);

      return;
    }
    else {
      addSelectData({
        state: selectState.value,
        shortName: event.short_name,
        subLabel: "",
        aff_pk: event.aff_pk,
        parent: event.parent,
      });
    }
    setSelectAffliates(event);
  };

  const handleSelectSublocal = (event) => {
    if (event.value === "all") {
      addSelectData({
        state: selectState.value,
        shortName: selectAffliates.short_name,
        subLabel: "",
        aff_pk: selectAffliates.aff_pk,
        parent: selectAffliates.parent,
      });
    } else {
      addSelectData({
        state: selectState.value,
        shortName: selectAffliates.short_name,
        subLabel: event.short_name,
        aff_pk: event.aff_pk,
        parent: event.parent,
      });
    }
  };

  const handleCheckSpam = (event) => {
    if (!isSpam) {
      setIsSpam(true);
    }
  };

  const onSubmit = async (data) => {
    if (!(selectUnion || selectState)) {
      notifyError('Please either select State or check the box for International Union staff')
    } else if (!isSpam) {
      setLoading(true);
      try {
        const { email, firstName, lastName, phone } = data;
        const dataSubmit = {
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          state: selectState.value,
          aff_pk: selectUnion ? listSelect[listSelect.length - 1].aff_pk : listSelect[listSelect.length - 1].aff_pk,

          phone_check: phoneCheck
        };
        if (listSelect.length < 1) {
          notifyError(VALIDATE_ERROR_MESSAGE);
          return;
        }
        const result = await reigsterUserData({
          data: { request: dataSubmit },
        });
        setLoading(false);
        if (result && result.status === 200) {
          notifySuccess(SUCCESS_MESSAGE);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      } catch (err) {
        console.log(err.response);
        if (err.response.status === 409) {
          notifyError(err.response.data.error)
        } else {
          notifyError(ERROR_MESSAGE);
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("spamm");
    }
  };

  const handleClear = () => {
    resetField("firstName");
    resetField("lastName");
    resetField("email");
    resetField("phone");
    setListSelect([]);
    setSelectState("");
    setSelectAffliates("");
  }

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (selectState) {
      getAffliates();
    }
  }, [selectState]);


  const unionhandler = (e) => {
    console.log(selectUnion);
  }
  const handleIntUnion = (cb) => {
    console.log(cb)
    setSelectUnion(!selectUnion);
    if (selectUnion === false) {
      setListSelect([]);
      setSelectState("");
      setSelectAffliates("");

      setListSelect([{
        state: "",
        shortName: "",
        subLabel: "",
        aff_pk: 14429,
        parent: "",
      }]);

    } else {

      setListSelect([]);
      setSelectState("");
      setSelectAffliates("");
    }

  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          <h3 className="linkTitle"><b>REGISTER</b></h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
            <TextInput
                    name="firstName"
                    register={register}
                    errors={errors}
                    placeholder="First Name*"
                    tabIndex={1}/>
                    <br/>
            <TextInput
                    name="lastName"
                    register={register}
                    errors={errors}
                    placeholder="Last Name*"
                    tabIndex={2}
                  /> <br/>
            <EmailInput
                name="email"
                register={register}
                errors={errors}
                placeholder="Email Address*"
                tabIndex={3}
              /><br/>
              <PhoneInput
                name="phone"
                register={register}
                errors={errors}
                placeholder="Phone"
                tabIndex={4}/><br/>
              <div className="checkBox">
                  <input
                    id="cellphone"
                    className="border"
                    value={phoneCheck}
                    type={"checkbox"}
                    onChange={(e) => {
                      setPhoneCheck(e.target.checked)
                    }}
                    tabIndex={5}
                  />
                  <p className="checkLabel">
                  By providing my cell phone number I consent to receive calls
                  (including recorded or autodialed calls, or texts) at that number
                  from AFSCME and its affiliated labor, political and charitable
                  organizations on any subject matter. My carrier’s rates may apply.
                  I may modify my preferences by visiting <a href="https://www.afscme.org/tcpa/">www.afscme.org/tcpa.</a>{" "}
                </p>
                </div>
                <div className="unionBox">
                  <div>
                    <input
                      id="intUnion"
                      className="border"
                      value={selectUnion}
                      type={"checkbox"}
                      onChange={(e) => {
                        handleIntUnion(e.target.value)
                      }}
                      tabIndex={6}/>
                      <label className="" htmlFor="intUnion">&nbsp;I am International Union staff</label>
                    </div><br/>
                    <div>
                    <Controller
                      name="state"
                      render={({ field }) => (
                        <Select
                          name="selectState"
                          isDisabled={selectUnion}
                          className="selectBox"
                          {...field}
                          options={dataState}
                          onChange={handleSelectState}
                          value={selectState}
                          placeholder="State"
                          style={{fontSize: 20, padding: 20, color: 'red'}}
                          tabIndex={7}
                        />
                      )}
                      control={control}
                    /><br/>
                    <Controller
                      name="affliates"
                      render={({ field }) => (
                        <Select
                          className="selectBox"
                          {...field}
                          options={dataAffliates}
                          isDisabled={selectState.length === 0 || selectUnion}
                          onChange={handleSelectAffliates}
                          value={selectAffliates}
                          placeholder="Affliate"
                          style={{fontSize: 20, padding: 20}}
                          tabIndex={8}
                        />
                      )}
                      control={control}
                    />
                    </div>
                    <div className="d-flex justify-content-end">
                      <Link className="linkText" to="/login" tabIndex={11}>SIGN IN</Link>
                  </div>
                </div><br/>
                <div className="d-flex justify-content-center">
                  <Stack justifyContent="center" alignItems="center" direction={{ xs: 'column', sm: 'column', md: 'row'}} spacing={2}>
                    <div>
                      {loading ? (
                        <DisableButton text="REGISTER" />
                      ) : (
                        <GreenButton text="REGISTER" tabIndex={9}/>
                      )}
                    </div>
                    <div onClick={handleClear} className="" >
                      <RedButton text="CLEAR" tabIndex={10} />
                    </div>
                </Stack>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
