import "./styles.css";
import logo from "assets/images/logo.png";

const UserFormHeader = ({menuBtn}) => {
    return(
        <div className="d-flex justify-content-center">
            <img className="img-responsive mainLogo" src={logo}/>
        </div>
    )
}
const UserForm = ({children, menuBtn}) => {
    return(
        <div>
            <br></br>
            <UserFormHeader menuBtn={menuBtn}/>
            <div>{children}</div>
        </div>
    )
}
export default UserForm