import { toast } from "react-toastify";


export const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 10000,
    theme: "colored",
  });

export const notifySuccess = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    theme: "colored",
  });

export const notifyInfo = (message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: 2000,
    theme: "colored",
  });
};

export const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString();
  // return date.toLocaleDateString("en-US", { timeZone: "America/New_York" });
};

const currentDate = new Date().toISOString().slice(0, 10);
// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.

export function dirtyValues(dirtyFields, allValues) {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
}

export function readFileArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });
}
