import { useState } from 'react'
import { deleteLink } from 'services/toolChest'
import { ConfirmationModal } from './block-view'
import { DeleteIcon } from 'assets/icons/Icons'
import { notifySuccess, notifyError } from "utils/helper";
import toolimg from "assets/images/tools.svg";


export default function ListView({ chestItems, refreshList, isDeletable}) {
  const [selectedItemRole, setSelectedItemRole] = useState(false)
  const [selectedTimestamp, setSelectedTimestamp] = useState()
  const [confirmationModalType, setConfirmationModalType] = useState()
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const deleteToolChest = (affPk_timestamp, role) => {
    setSelectedTimestamp(affPk_timestamp)
    setSelectedItemRole(role)
    setConfirmationModalType('delete')
    setConfirmationModalOpen(true)
  }
  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false)
  }
  const confirmClicked = async () => {
    setLoading(true)
    if (confirmationModalType === 'delete') {
      const request_data = {
        delete: [{
          role: selectedItemRole,
          affPk_timestamp: selectedTimestamp
        }]
      }
      const response = await deleteLink(request_data);
      if (response.status === 200) {
        notifySuccess('Item deleted');
        setConfirmationModalOpen(false)
        refreshList()
      }
    }
  }
  return (
    <ul className="md:grid-flow-row sm:grid-flow-row lg:grid-cols-1 grid gap-4">
      {chestItems.map((chestItem) => {
        //console.log(chestItem);
        return (
          <li key={chestItem.affPk_timestamp}>

            <div type="button" className="lg:ml-auto sm:grid-flow-row grid grid-cols-auto min-w-fit lg:justify-end float-right">

              {(isDeletable(chestItem)) && <DeleteIcon type="button" className="hover:border-solid text-white hover: font-bold py-2 px-4 rounded-lg w-fit mr-3 mb-4" onClick={() => deleteToolChest(chestItem.affPk_timestamp)}></DeleteIcon>
              }
            </div>
            <a href={chestItem.url} target="_blank" rel="noreferrer" style={{border: 'solid aqua'}}>
              <div className="max-w-sm w-full lg:max-w-full lg:flex shadow-lg">
                <div className="max-w-sm rounded overflow-hidden ">
                  {console.log(chestItem.metadata_info)}
                {chestItem.metadata_info === 0 ? <img src={chestItem.metadata_info.image && chestItem.metadata_info.image.url} alt="" className="h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" /> : <img src={toolimg} alt="" className="h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" />}

                </div>
                <div className="rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                  <div className="mb-8">
                    <div className="text-gray-900 font-bold text-xl mb-2">{chestItem.metadata_info.title}</div>
                    <p className="text-gray-400 text-base"> 232323 {chestItem.metadata_info && chestItem.metadata_info.description ? chestItem.metadata_info.description : chestItem.description ? chestItem.description: "" } </p>
                  </div>
                  <div className="flex items-center">
                    <div className="text-md">
                      <p className="text-gray-400">{chestItem.short_name}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="flex-1">
                  {chestItem.metadata_info.image.url && <img src={chestItem.metadata_info.image.url} alt="" width="120" height="176" className="lg:float-left rounded-md bg-slate-100" />}
                </div>
                <div className="flex-1 justify-center hover:bg-slate-200 group rounded-md pl-2 shadow-sm sm:grid-flow-row grid lg:grid-cols-1">
                  <h2 className="font-semibold text-slate-900 truncate">{chestItem.metadata_info.title}</h2>

                    <div className="flex-1 w-full mt-2 font-normal">
                      {chestItem.metadata_info.description}
                    </div>
                    <div className="flex-none w-full mt-2 font-normal">
                      <dd className="text-slate-400">{chestItem.short_name || chestItem.metadata_info.url}</dd>
                    </div>
                </div> */}
            </a>
          </li>
        )
      })}



      {isConfirmationModalOpen && confirmationModalType &&
        <ConfirmationModal
          type={confirmationModalType}
          closeModal={closeConfirmationModal}
          modalType={confirmationModalType}
          confirmClicked={confirmClicked}
          loading={loading}
        />}
    </ul>
  )
}
