import { useEffect } from "react";
import { SmallButton } from "./Button";

const CardRequest = ({ data, handleApprove, handleDeny }) => {
  const affiliates = [];

  const sortAffiliates = () => {
    const listAffiliates = [];
    // state first
    data.affiliates.map((item) => {
      if (item.state) {
        return listAffiliates.unshift(item);
      }
      return listAffiliates.push(item);
    });
    listAffiliates.map((item) => {
      return affiliates.push(item.state || item.short_name);
    });
    // sort by aff_pk
    affiliates.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    });
  };

  //sortAffiliates();

  return (
    <div className="container-fluid URcard bg-white">
      <p className="font-bold text-green-10 text-2xl mb-3">
        {data?.first_name} {data?.last_name}
      </p>
      <p className="text-sliver-15 mb-2">
        {data?.email}
      </p>
      <p className="text-sliver-15 mb-2">
        Affiliation: {data?.short_name}
      </p>
      {/* <div className="flex flex-wrap justify-start items-center py-2 h-1 overflow-auto overflow-x-hidden my-2">
        {affiliates.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-blue-10 rounded-md text-[10px] lg:text-xs py-1 px-2 mr-1 mb-1"
            >
              <p>{item}</p>
            </div>
          );
        })}
      </div> */}
      <div className="place-content-center text-lg text-bold gap-1 pt-1 grid-flow-col grid">
        <div onClick={handleDeny}>
          <SmallButton text="DENY" color="bg-red-10" />
        </div>
        <div onClick={handleApprove}>
          <SmallButton text="APPROVE" color="bg-green-10" />
        </div>
      </div>
    </div>
  );
};
export default CardRequest;
