import { useState, useEffect } from "react";
import { updateUserInfo } from "services/userUpdate";
import BlockView from "./block-view";
import { requestFilterUserList, requestUserList } from "services/userList";
import { Link } from "react-router-dom";
import { GreenDownIcon, SearchIcon, WhiteDownIcon } from "assets/icons/Icons";
import useCollapse from "react-collapsed";
import FilterBox from "./FilterBox";
import { notifyError, notifySuccess } from "utils/helper";
import { ToastContainer } from "react-toastify";
import { debounce } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "components/Loading";
import arrow from "assets/images/down-arrow-svgrepo-com.svg";
import { useSelector } from "react-redux";
import Select from "react-select";

const confirmation_modal_data = {
  delete: {
    confirmBtnTxt: "Delete",
    msg: "Are you sure, you want to delete this user?",
  },
  deactivate: {
    confirmBtnTxt: "Deactivate",
    msg: "Are you sure, you want to suspend this user?",
  },
  activate: {
    confirmBtnTxt: "Activate",
    msg: "Are you sure, you want to activate this user?",
  },
  update_user_role: {
    confirmBtnTxt: "Update user role",
    field_title: "Choose a user role",
  },
  update_password: {
    confirmBtnTxt: "Update password",
    field_title: "Enter a new password",
  },
  update_affiliate: {
    confirmBtnTxt: "Modify",
    field_title: "Select Affiliate Group",
  },
};

export const ConfirmationModal = ({
  closeModal,
  modalType,
  confirmClicked,
  loading,
}) => {
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="relative inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {confirmation_modal_data[modalType].confirmBtnTxt} this
                  account
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {confirmation_modal_data[modalType].msg}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={confirmClicked}
              type="button"
              disabled={loading}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {loading
                ? "Loading..."
                : confirmation_modal_data[modalType].confirmBtnTxt}
            </button>
            <button
              onClick={closeModal}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserInfoUpdateModal = ({
  closeModal,
  modalType,
  confirmClicked,
  loading,
  updateFieldValue,
  fieldValue,
  dataState,
  dataAffliates,
  handleSelectState,
  handleSelectAffliates,
  selectState,
  selectAffliates,
}) => {
  const authInfo = useSelector((state) => state.auth);

  return (
    <div
      className="fixed z-10 inset-0"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="relative inline-block bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {confirmation_modal_data[modalType].confirmBtnTxt}{" "}
                  {modalType === "update_affiliate"
                    ? "this user's Affiliate Group"
                    : "for this account"}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {confirmation_modal_data[modalType].field_title}
                  </p>
                  {modalType === "update_password" && (
                    <input
                      type="password"
                      onChange={(e) => updateFieldValue(e.target.value)}
                      style={{ border: "1px solid #aaa" }}
                    />
                  )}
                  {modalType === "update_user_role" && (
                    <select
                      style={{ border: "1px solid #aaa", color: "black" }}
                      defaultValue={fieldValue}
                      onChange={(e) => updateFieldValue(e.target.value)}
                    >
                      {authInfo?.role === "system_admin" && (
                        <option value="system_admin">System Admin</option>
                      )}
                      <option value="affiliate_admin">Affiliate Admin</option>
                      <option value="user">User</option>
                      <option value="viewer">Viewer</option>
                    </select>
                  )}

                  {modalType === "update_affiliate" && (
                    <div className="space-y-2">
                      <Select
                        name="selectState"
                        className="selectBox"
                        options={dataState}
                        onChange={handleSelectState}
                        value={selectState}
                        placeholder="State"
                        style={{ fontSize: 20, padding: 20 }}
                        tabIndex={7}
                      />
                      <Select
                        className="selectBox"
                        options={dataAffliates}
                        isDisabled={selectState.length === 0}
                        onChange={handleSelectAffliates}
                        value={selectAffliates}
                        placeholder="Affiliate"
                        style={{ fontSize: 20, padding: 20 }}
                        tabIndex={8}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={confirmClicked}
              type="button"
              disabled={loading}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {loading
                ? "Loading..."
                : confirmation_modal_data[modalType].confirmBtnTxt}
            </button>
            <button
              onClick={closeModal}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function UserList({ view }) {
  const authInfo = useSelector((state) => state.auth);

  const [page, setPage] = useState(0);
  const [keySearch, setKeySearch] = useState(null);
  const [fieldsSearch, setFieldsSearch] = useState(null);

  const [userListItem, setUserListItem] = useState([]);
  const [cloneUserList, setCloneUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isDeleteUsers, setIsDeleteUsers] = useState(false);
  const [isFilterInactive, setIsFilterInactive] = useState(false);

  const [userModalType, setUserModalType] = useState();
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [selectedUserEmail, setSelectedUserEmail] = useState();
  const [actionLoading, setActionLoading] = useState(false);
  const [fieldValue, updateFieldValue] = useState();
  const [dataUserSelected, setDataUserSelected] = useState();

  const [dataState, setDataState] = useState([]);
  const [selectState, setSelectState] = useState("");

  const [dataAffliates, setDataAffliates] = useState([]);
  const [selectAffliates, setSelectAffliates] = useState("");

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const fetchList = async () => {
    if (page !== -1) {
      setLoading(true);
      try {
        const { data } = await requestUserList({
          params: {
            offset: page * 50,
            search: keySearch,
            fields: fieldsSearch,
          },
        });
        console.log("🚀 ===== file: index.js ===== line 261 ===== data", data);
        if (data?.length > 0) {
          // setUserListItem([...data]);
          // setCloneUserList([...data]);
          setUserListItem([...userListItem, ...data]);
          setCloneUserList([...userListItem, ...data]);
          setPage(page + 1);
        } else {
          setPage(-1);
        }
      } catch (error) {
        console.log(
          "🚀 ===== file: index.js ===== line 264 ===== error",
          error
        );
      }
      setLoading(false);
    }
  };

  const handleFilterInactiveUsers = async () => {
    setPage(0);

    if (!isFilterInactive) {
      setUserListItem([]);
      setCloneUserList([]);
      setFieldsSearch("user_status");
      setKeySearch("delete");
    } else {
      setUserListItem([]);
      setCloneUserList([]);
      setFieldsSearch(null);
      setKeySearch(null);
    }

    setIsFilterInactive(!isFilterInactive);
  };

  const handleSearch = debounce(async (event) => {
    setPage(0);
    setUserListItem([]);
    setCloneUserList([]);
    setKeySearch(event.target.value);
  }, 500);

  const updateUserRole = (email) => {
    updateFieldValue(undefined);
    setSelectedUserEmail(email);
    setUserModalType("update_user_role");
    setUserModalOpen(true);
    setModalType("value_based");
  };
  const changePassword = async (email) => {
    const payload = {
      updatePassword: [
        {
          email,
        },
      ],
    };

    try {
      await updateUserInfo(payload);
      notifySuccess("Email sent");
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteUser = (email) => {
    setSelectedUserEmail(email);
    setUserModalType("delete");
    setUserModalOpen(true);
    setModalType("confirmation");
  };
  const deactivateUserAccount = (email) => {
    setSelectedUserEmail(email);
    setUserModalType("deactivate");
    setUserModalOpen(true);
    setModalType("confirmation");
  };
  const activateUserAccount = (email) => {
    setSelectedUserEmail(email);
    setUserModalType("activate");
    setUserModalOpen(true);
    setModalType("confirmation");
  };
  const closeConfirmationModal = () => {
    setUserModalOpen(false);
    setUserModalType(undefined);
    setModalType(undefined);
    setModalType("confirmation");
  };

  const handleChangeAffiliate = (userInformation) => {
    updateFieldValue(undefined);
    setDataUserSelected(userInformation);
    setUserModalType("update_affiliate");
    setUserModalOpen(true);
    setModalType("value_based");
  };

  const moveUpdatedObjToTop = (selectedUserEmail, userlist) => {
    var newUserlist =
      userlist && userlist.length ? userlist : [...userListItem];
    const selectedEmailObj = newUserlist.filter(
      (element) =>
        element.email.toLowerCase() === selectedUserEmail.toLowerCase()
    );
    const index = newUserlist.indexOf(selectedEmailObj[0]);
    newUserlist.splice(index, 1);

    newUserlist = [selectedEmailObj[0], ...newUserlist];

    setUserListItem(newUserlist);
  };

  const confirmClicked = async () => {
    setActionLoading(true);
    // update affiliate
    if (userModalType === "update_affiliate") {
      if (
        selectState &&
        selectAffliates &&
        selectState?.value !== dataUserSelected?.state &&
        selectAffliates?.value !== dataUserSelected?.affiliate_assoc
      ) {
        const request_data = {
          editUser: [
            {
              email: dataUserSelected?.email,
              aff_pk: selectAffliates?.aff_pk,
            },
          ],
        };

        try {
          await updateUserInfo(request_data);
        } catch (error) {
          console.log("🚀 ===== error", error);
        }
      }

      closeConfirmationModal();
    }

    if (userModalType === "delete") {
      const request_data = {
        cancel: [
          {
            email: selectedUserEmail,
          },
        ],
      };
      const response = await updateUserInfo(request_data);
      if (response.status === 200) {
        closeConfirmationModal();
      }
      var newUserlist = [...userListItem];

      //delete
      const selectedEmailObj = newUserlist.filter(
        (element) =>
          element.email.toLowerCase() === selectedUserEmail.toLowerCase()
      );
      const index = newUserlist.indexOf(selectedEmailObj[0]);
      newUserlist.splice(index, 1);
      setUserListItem(newUserlist);
    } else if (userModalType === "deactivate") {
      const request_data = {
        suspend: [
          {
            email: selectedUserEmail,
          },
        ],
      };
      const response = await updateUserInfo(request_data);
      if (response.status === 200) {
        var newUserlist = userListItem.map((element, index) => {
          if (element.email.toLowerCase() === selectedUserEmail.toLowerCase()) {
            element.user_status = "disable";
          }
          return element;
        });
        moveUpdatedObjToTop(selectedUserEmail, newUserlist);
        closeConfirmationModal();
      }
    } else if (userModalType === "activate") {
      const request_data = {
        resume: [
          {
            email: selectedUserEmail,
          },
        ],
      };
      const response = await updateUserInfo(request_data);
      if (response.status === 200) {
        var newUserlist = userListItem.map((element, index) => {
          if (element.email.toLowerCase() === selectedUserEmail.toLowerCase()) {
            element.user_status = "active";
          }
          return element;
        });

        moveUpdatedObjToTop(selectedUserEmail, newUserlist);

        closeConfirmationModal();
      }
    } else if (userModalType === "update_user_role") {
      if (selectedUserEmail === authInfo?.email) {
        notifyError("Can not update this user!");
        setActionLoading(false);
        setLoading(false);
        return;
      }

      const request_data = {
        roleSet: [
          {
            email: selectedUserEmail,
            role: fieldValue,
          },
        ],
      };

      var newUserlist = userListItem.map((element, index) => {
        if (element.email.toLowerCase() === selectedUserEmail.toLowerCase()) {
          element.role = fieldValue;
        }
        return element;
      });
      const selectedEmailObj = newUserlist.filter(
        (element) =>
          element.email.toLowerCase() === selectedUserEmail.toLowerCase()
      );
      const index = newUserlist.indexOf(selectedEmailObj[0]);
      newUserlist.splice(index, 1);
      newUserlist = [selectedEmailObj[0], ...newUserlist];
      setUserListItem(newUserlist);

      const response = await updateUserInfo(request_data);
      if (response.status === 200) {
        closeConfirmationModal();
      }
    } else if (userModalType === "update_password") {
      const request_data = {
        updatePassword: [
          {
            email: selectedUserEmail,
            password: fieldValue,
          },
        ],
      };
      try {
        const response = await updateUserInfo(request_data);
        if (response.status === 200) {
          moveUpdatedObjToTop(selectedUserEmail);
          closeConfirmationModal();
        }
      } catch (error) {
        notifyError(error.response.data.error.message);
      }
    }
    setActionLoading(false);
    setLoading(false);
    //fetchList()
  };

  const toggleDelete = () => {
    setIsDeleteUsers(!isDeleteUsers);
  };

  useEffect(() => {
    fetchList();
  }, [keySearch]);

  return (
    <>
      <ToastContainer />
      <Loading isVisible={loading} />
      <div className="container-fluid listBox-Admin">
        <div className="row">
          <div className="flex justify-center">
            <Link
              to="/admin/view-users"
              className="text-center text-white text-3xl font-bold no-underline"
            >
              <h2 className="linkTitle">
                <b>User List</b>
              </h2>
            </Link>
            <div
              className="ml-4 -translate-y-2 cursor-pointer"
              {...getToggleProps()}
            >
              {isExpanded ? (
                <img
                  src={arrow}
                  width="25"
                  className="optArrowUser filter-green"
                />
              ) : (
                <img
                  src={arrow}
                  width="25"
                  className="optArrowUser filter-white"
                />
              )}
            </div>
          </div>

          <div className="flex justify-center" {...getCollapseProps()}>
            <div className="text-xl font-bold text-center">
              {/* Inactive Users */}
              <div
                className={`hover:opacity-80 ${
                  isFilterInactive ? "text-green-10" : "text-white"
                }`}
                onClick={handleFilterInactiveUsers}
              >
                <div className="no-underline hover:text-green-10 inline-block cursor-pointer">
                  Inactive Users
                </div>
              </div>
              {/* Create User */}
              <div className="hover:opacity-80">
                <Link
                  to={authInfo.role === "affiliate_admin" ? "/affiliate-admin/create-user" : "/admin/create-user"}
                  className="no-underline text-white inline-block cursor-pointer"
                >
                  <p className="hover:text-green-10 m-0">Create User</p>
                </Link>
              </div>
              {/* Filter */}
              <FilterBox
                cloneUserList={cloneUserList}
                userListItem={userListItem}
                setUserListItem={setUserListItem}
              />

              <p
                className={`m-0 inline-block cursor-pointer hover:text-green-10 hover:opacity-80 ${
                  isDeleteUsers && "text-green-20"
                }`}
                onClick={toggleDelete}
              >
                Delete
              </p>

              <div>
                <div className="flex items-center bg-white rounded-md mx-auto w-fit">
                  <div className="pl-2 text-blue-10 border-r">
                    <SearchIcon />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="text-base font-normal text-black-10 p-1 outline-none rounded-md w-[200px]"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <InfiniteScroll
              className="space-y-6 mt-10"
              dataLength={userListItem.length}
              next={fetchList}
              hasMore={true}
            >
              <BlockView
                userItems={userListItem}
                {...{
                  userModalType,
                  setUserModalType,
                  isUserModalOpen,
                  setUserModalOpen,
                  modalType,
                  setModalType,
                  selectedUserEmail,
                  setSelectedUserEmail,
                  actionLoading,
                  setActionLoading,
                  fieldValue,
                  updateFieldValue,
                  updateUserRole,
                  changePassword,
                  deleteUser,
                  deactivateUserAccount,
                  activateUserAccount,
                  closeConfirmationModal,
                  confirmClicked,
                  isDeleteUsers,
                  setLoading,
                  fetchList,
                  handleChangeAffiliate,
                  dataUserSelected,
                  dataState,
                  setDataState,
                  selectState,
                  setSelectState,
                  dataAffliates,
                  setDataAffliates,
                  selectAffliates,
                  setSelectAffliates,
                }}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
}
