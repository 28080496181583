import {useState} from 'react'
import Header from "./header";
import SideBar from "./sideBar";
import UserForm from "components/layout/user_form";
import { MenuCloseIcon } from 'assets/icons/Icons';
import logo from "assets/images/logo.png";
import NAVbar from 'components/NavBar';
import 'styles/engage-tool.css';

const Layout = ({ children, title }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const MenuBtn = <button style={{ border: 'none' }} className="relative z-10 mt-10 pl-2 p-1 mr-4 lg:ml-4" onClick={() => {setSidebarOpen(!sidebarOpen);}}>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke="#D6D6D6" className="h-8 w-8">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
  </svg>
</button>

const MenuCloseBtn = <button style={{ border: 'none' }} className="relative z-10 mt-10 pl-2 p-1 mr-4 lg:ml-4" onClick={() => {setSidebarOpen(!sidebarOpen);}}>
<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#D6D6D6">
  <path stroke-linecap="round" stroke-linejoin="round"  stroke-width="2" d="M6 18L18 6M6 6l12 12" />
</svg>
</button>
  return (
    <>
    <NAVbar/>
    <div>
      {children}
    </div>
    </>
  );
};

export default Layout;
