import Layout from "components/layout";
import BestPracticeForm from "components/best-practice";
import React from "react";
import { TITLE_BP } from "utils/constants";
import { Link } from "react-router-dom";

export default function BestPractice() {
  return (
    <Layout title={TITLE_BP}>
      <navbar id="nav2">
        <a href='./dashboard'>Calendar</a>&nbsp;/&nbsp;
        <a href='./tool-chest'>Tool Chest</a>&nbsp;/&nbsp;
        <Link to='/best-practice' className="active"><b>Best Practices</b></Link>
      </navbar>
      {/*<div style={{fontSize:24, textAlign: 'center', width:'100%', fonstWeight: 'bold'}}>List of Tool chest</div>*/}
      <BestPracticeForm/>
    </Layout>
  );
}
