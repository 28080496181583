import React, { useState } from "react";
import { DisableButton, GreenButton } from "components/Button";
import Input from "components/Input";
import { Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { schemaSignIn } from "utils/schema";
import "bootstrap/dist/css/bootstrap.css";
import "styles/engage-tool.css";

export default function Form({ switchForm, userData, onSubmit }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSignIn),
  });



  return (
    <div className="Center">
      <div className="container-fluid login">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <span className="titleText">
              <b>LOGIN TO YOUR ACCOUNT</b>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-center">
            {/* login form */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative mb-2">
                <Input
                  type="text"
                  name="username"
                  register={register}
                  errors={errors}
                  placeholder="Email address"
                />
                {errors.username && (
                  <p className="absolute  text-red-600 right-0">
                    {errors.username.message}
                  </p>
                )}
              </div>
              <br></br>
              <div className="relative mb-8">
                <Input
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  placeholder="Password"
                />
                {errors.password && (
                  <p className="absolute  text-red-600 right-0">{errors.password.message}</p>
                )}
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <Link className="linkText" to="/forgot_password">
                    <b>FORGOT YOUR PASSWORD</b>
                  </Link>
                </div>
                <div>
                  <Link className="linkText" to="/register">
                    <b>APPLY FOR AN ACCOUNT</b>
                  </Link>
                </div>
              </div>
              <br></br>
              <br></br>
              <div className="flex justify-content-center">
                {loading ? (
                  <DisableButton text="SIGN IN" />
                ) : (
                  <GreenButton text="SIGN IN" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
