import { useState, useEffect } from "react";
import { Auth } from "@aws-amplify/auth/lib-esm/Auth";
import { useSelector } from "react-redux";
import BlockView from "./block-view";
import ListView from "./list-view";
import AddToolChestModal from "./add-tool-chest-modal";
import { requestChestLinkList } from "services/toolChest";
import LoadingCard from "components/loading-card";

export default function ChestList({ view, setView, isOpen, setIsOpen }) {
  const [loggedInEmail, setLoggedInEmail] = useState(null);
  const [chestLinkItem, setChestLinkItem] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchList = async () => {
    setLoading(true);
    const response = await requestChestLinkList();
    console.log(response);
    if (response.status === 200) {
      setChestLinkItem(response.data);
    }
    setLoading(false);
  };

  const getLoggedInEmail = async () => {
    const response = await Auth.currentAuthenticatedUser();
    setLoggedInEmail(response.attributes.email);
  };

  useEffect(() => {
    fetchList();
    getLoggedInEmail();
  }, []);

  const authInfo = useSelector((state) => state.auth);

  const isDeletable = (chestItem) => {
    console.log('🚀 ===== chestItem', chestItem);
    //console.log("chestitem: >>>", chestItem);
    if (authInfo.role === "user" || authInfo.role === "viewer") {
      return false;
    }


    return (
      (chestItem.owner === loggedInEmail ||
        authInfo.role === "system_admin" ||
        (authInfo.role === "affiliate_admin" &&
          authInfo.aff_pk === chestItem.aff_pk)) &&
      !(
        authInfo.role === "affiliate_admin" && chestItem.role === "system_admin"
      )
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          <AddToolChestModal
            open={isOpen}
            setOpen={setIsOpen}
            refreshList={fetchList}
          />
        </div>
      </div>

      {loading ? (
        <LoadingCard />
      ) : (
        <div>
          {view === "block" && (
            <BlockView
              chestItems={chestLinkItem}
              refreshList={fetchList}
              isDeletable={isDeletable}
            />
          )}
          {view === "list" && (
            <ListView
              chestItems={chestLinkItem}
              refreshList={fetchList}
              isDeletable={isDeletable}
            />
          )}
        </div>
      )}
    </div>
  );
}
