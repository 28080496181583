import Layout from "components/layout";
import React, { useEffect, useState } from "react";
import { TITLE_REGISTRATION_FORM } from "utils/constants";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import arrow from "assets/images/down-arrow-svgrepo-com.svg";
import useCollapse from "react-collapsed";
import AddEvents from "./AddEvents";
import { requestGetCalendarEvent } from "services/calendar";
import UpdateEvent from "./UpdateEvents";
import Loading from "components/Loading";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

export default function Dashboard() {
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const [loading, setLoading] = useState(true);
  const [searchDate, setSearchDate] = useState(null);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const [allEvents, setAllEvents] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const openUpdateModal = () => setShowUpdateModal(true);
  const closeUpdateModal = () => setShowUpdateModal(false);

  const authInfo = useSelector((state) => state.auth);

  const getCalendarEvent = async (date) => {
    console.log("🚀 ===== date", date);

    const currentDay = new Date();
    let month = currentDay.getMonth() + 1;
    let year = currentDay.getFullYear();

    if (date) {
      const searchDate = new Date(date);

      month = searchDate.getMonth() + 1;
      year = searchDate.getFullYear();
    }

    setLoading(true);
    try {
      const { data } = await requestGetCalendarEvent({
        params: { month: month, year: year },
      });
      if (data && data?.data) {
        if (authInfo.aff_pk === "14429") {
          const events = data?.data.map((event) => {
            return {
              ...event,
              allDay: false,
              start: new Date(event?.start_date),
              end: new Date(event?.end_date),
            };
          });
          setAllEvents(events);
          //console.log("here1");
        } else {
          const events = data?.data.map((event) => {
            if (
              event.aff_pk === "14429" ||
              authInfo.role === "system_admin" ||
              event.aff_pk === authInfo.aff_pk
            )
              return {
                ...event,
                allDay: false,
                start: new Date(event?.start_date),
                end: new Date(event?.end_date),
              };
          });
          setAllEvents(events);

          //console.log("here2");
        }
      }
    } catch (error) {
      console.log("error", { ...error });
    }
    setLoading(false);
  };

  const handleSelectEvent = (data) => {
    if (
      data === "agenda" ||
      data === "month" ||
      data === "week" ||
      data === "day"
    )
      return;

    setSelectedEvent(data);
    if (authInfo?.role !== "viewer") {
      openUpdateModal();
    }
  };

  const handleSearchDate = (date) => {
    setSearchDate(date);
    getCalendarEvent(date);
  };

  useEffect(() => {
    getCalendarEvent();
  }, []);

  return (
    <Layout title={TITLE_REGISTRATION_FORM}>
      <Loading isVisible={loading} />
      <ToastContainer className="mt-1" />

      <div id="nav2">
        <a href="./dashboard" className="active Cal">
          <b>Calendar</b>
        </a>
        &nbsp;/&nbsp;
        <a href="./tool-chest" className="tool">
          Tool Chest
        </a>
        &nbsp;/&nbsp;
        <a href="./best-practice">Best Practices</a>
      </div>
      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            {authInfo.role !== "viewer" ? (
              <>
                <div
                  className="d-flex justify-content-center"
                  {...getToggleProps()}
                >
                  <h2 className="linkTitle">
                    <b>Calendar</b>
                  </h2>

                  {isExpanded ? (
                    <img
                      src={arrow}
                      width="25"
                      className="optArrow filter-green"
                    />
                  ) : (
                    <img
                      src={arrow}
                      width="25"
                      className="optArrow filter-white"
                    />
                  )}
                </div>
                <div
                  className="flex flex-col justify-center p-4 rounded-md text-xl bg-blue-20 w-fit mx-auto space-y-4"
                  {...getCollapseProps()}
                >
                  <div onClick={openAddModal} className="addLink">
                    Add Calendar Event
                  </div>
                  {/* search */}
                  {/* <div className="flex items-center bg-white rounded-md mx-auto w-fit">
                <div className="pl-2 text-blue-10 border-r">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  className="text-base font-normal text-black-10 p-1 outline-none rounded-md w-[200px]"
                  onChange={handleSearch}
                />
              </div> */}
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <h2 className="linkTitle">
                  <b>Calendar</b>
                </h2>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div id="calArea" className="col-md-12">
            <Calendar
              localizer={localizer}
              events={allEvents}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={handleSelectEvent}
              onView={handleSelectEvent}
              style={{ height: 500, margin: "50px", width: "90%" }}
              eventPropGetter={(event) => {
                let IU = { backgroundColor: "#00a94f" };
                //console.log(event.aff_pk);
                if (event.aff_pk === "14429") {
                  return {
                    className: "bg-green",
                    style: IU,
                  };
                }
              }}
              date={searchDate}
              onNavigate={handleSearchDate}
            />
          </div>
        </div>
      </div>

      {showAddModal && (
        <div className="absolute inset-0 z-50 bg-white/30 p-10 flex items-center justify-center">
          <div className="h-[90%] bg-blue-20 rounded-lg flex-1 max-w-2xl overflow-auto">
            <AddEvents
              setLoading={setLoading}
              closeAddModal={closeAddModal}
              getCalendarEvent={getCalendarEvent}
            />
          </div>
        </div>
      )}
      {showUpdateModal && (
        <UpdateEvent
          setLoading={setLoading}
          closeModal={closeUpdateModal}
          data={selectedEvent}
          getCalendarEvent={getCalendarEvent}
        />
      )}
    </Layout>
  );
}
