import React from "react";
import Form from "./components/Form";
import UserForm from "components/layout/user_form";

export default function Register() {
  return (
    <div>
    <UserForm>
      <br></br>
      <br></br>
      <Form />
    </UserForm>
  </div>
  );
}
