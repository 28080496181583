const Header = ({ title }) => {
  return (
    <header className="h-20 md:h-24 w-full relative bg-green-10 flex justify-center items-center">
      <div className="text-white text-lg sm:text-2xl text-center md:text-3xl">
        {title}
      </div>
    </header>
  );
};

export default Header;
