import { SmallWhiteDownIcon } from "assets/icons/Icons";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { fetchAffliatessAPI, fetchStateAPI } from "services/register";
import { DATA_ROLES } from "utils/constants";
import { notifyError } from "utils/helper";

function FilterBox({ userListItem, setUserListItem, cloneUserList }) {
  const [isShowBoxFilter, setIsShowBoxFilter] = useState(false);
  const [tab, setTab] = useState("filter");
  const [dataState, setDataState] = useState([]);

  const [dataAffliates, setDataAffliates] = useState([]);

  const [role, setRole] = useState("");
  const [state, setState] = useState("");
  const [affliates, setAffliates] = useState("");

  const toggleShowBoxFilter = () => {
    const show = isShowBoxFilter;
    setIsShowBoxFilter(!show);
  };

  const getStates = async () => {
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      selectStateData.sort((a, b) => {
        if (a.value > b.value) {
          return 1;
        }
        if (b.value > a.value) {
          return -1;
        }
        return 0;
      });

      setDataState([{ value: "All", label: "All" }, ...selectStateData]);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
  };

  const getAffliates = async () => {
    if (state.value === "All") return;
    try {
      const response = await fetchAffliatessAPI({
        data: { state: state.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => {
            if (x.type === "C" || x.type === "R" || x.type === "L") {
              return x;
            }
          });
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([
            //{ value: "all", label: "All", state: selectAffliatesData[0].state },
            ...selectAffliatesData,
          ]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  };

  const handleFilter = () => {
    let dataRole = cloneUserList;
    if (role && role.value !== "All") {
      dataRole = cloneUserList.filter((user) => {
        return user.role === role.value;
      });
    }

    let dataState = dataRole;
    if (state && state.value !== "All") {
      dataState = dataRole.filter((user) => {
        return user.state === state.value;
      });
    }

    let dataAff = dataState;
    console.log(
      "🚀 ===== file: FilterBox.js ===== line 118 ===== dataState",
      dataState
    );
    if (affliates) {
      dataAff = dataState.filter((user) => {
        return user.affiliate_assoc === affliates.type + affliates.chapter;
      });
    }

    setUserListItem(dataAff);
  };

  const handleChangeState = (selectState) => {
    setAffliates(null);
    setState(selectState);
  };

  const handleChangeRole = (selectRole) => {
    setRole(selectRole);
  };

  const handleChangeAffliate = (selectAffliate) => {
    setAffliates(selectAffliate);
  };

  const handleSort = (type) => {
    let dataSort = [...userListItem];

    if (type === "a-z") {
      dataSort.sort(function (a, b) {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });
    }

    if (type === "date-added") {
      dataSort.sort(function (a, b) {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
    }

    setUserListItem(dataSort);
  };

  const cancelFilter = () => {
    setAffliates(null);
    setState(null);
    setRole(null);
    setUserListItem(cloneUserList);
  };

  const disableAff = () => {
    if (!state || state.value === "All") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (state) {
      getAffliates();
    }
  }, [state]);

  useEffect(() => {
    handleFilter();
  }, [role, state, affliates]);

  return (
    <div>
      <div
        className="flex justify-center items-center cursor-pointer hover:opacity-80"
        onClick={toggleShowBoxFilter}
      >
        <p className="mb-0 mr-2 hover:text-green-10">Filter</p>
        <SmallWhiteDownIcon />
      </div>
      {isShowBoxFilter && (
        <div className="p-3 rounded-lg bg-blue-30 w-60 mx-auto">
          {/* Tab */}
          <div className="flex justify-around gap-4">
            <p
              className={`mb-0 cursor-pointer ${
                tab === "filter" && "text-green-20"
              }`}
              onClick={() => setTab("filter")}
            >
              FILTER
            </p>
            <p
              className={`mb-0 cursor-pointer ${
                tab === "sort" && "text-green-20"
              }`}
              onClick={() => setTab("sort")}
            >
              SORT
            </p>
          </div>
          {tab === "filter" && (
            <div className="space-y-2 text-base font-bold text-black">
              <Select
                options={dataState}
                placeholder="State"
                value={state}
                onChange={handleChangeState}
              />

              <Select
                options={dataAffliates}
                placeholder="Affliate"
                value={affliates}
                onChange={handleChangeAffliate}
                isDisabled={(!state || state.value === "All") && true}
              />

              <Select
                options={[{ value: "All", label: "All" }, ...DATA_ROLES]}
                placeholder="User role"
                value={role}
                onChange={handleChangeRole}
              />
              <button
                className="bg-green-20 text-white font-bold flex-1 rounded-[4px] py-1 cursor-pointer w-full"
                onClick={cancelFilter}
              >
                Cancel
              </button>
            </div>
          )}
          {tab === "sort" && (
            <div className="space-y-2 text-base font-bold text-black">
              <div className="flex gap-4 items-center">
                <input
                  type="radio"
                  id="a-z"
                  name="sort"
                  onChange={() => handleSort("a-z")}
                />
                <label
                  htmlFor="a-z"
                  className="bg-white flex-1 rounded-lg py-1 cursor-pointer"
                >
                  A - Z
                </label>
              </div>

              <div className="flex gap-4 items-center">
                <input
                  type="radio"
                  id="date"
                  name="sort"
                  onChange={() => handleSort("date-added")}
                />
                <label
                  htmlFor="date"
                  className="bg-white flex-1 rounded-lg py-1 cursor-pointer"
                >
                  Date Added
                </label>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FilterBox;
