import React from "react";
import { SearchIcon } from "assets/icons/Icons";
import "bootstrap/dist/css/bootstrap.css";

export default function Input({
  type,
  name,
  register,
  errors,
  placeholder,
  onChange,
}) {
  return (
    <div className="form-group">
      <input
        className="form-control Box"
        type={type}
        name={name}
        placeholder={placeholder}
        {...register(name, {
          required: true,
        })}
        onChange={onChange}
      />
      {/* {errors[name] && (
        <p className="">
          {errors[name].message}
        </p>
      )} */}
    </div>
  );
}

export const SearchInput = () => {
  return (
    <div className="relative hidden">
      <input
        className="border w-full pl-10 h-10 xl:h-12 xl:text-lg rounded-md text-base outline-none px-2 focus:border-blue-400 shadow-md"
        placeholder="Search"
        type="text"
      />
      <button className="absolute text-gray-600 top-0 bottom-0 left-2">
        <SearchIcon />
      </button>
    </div>
  );
};
