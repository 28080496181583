import CardRequest from "components/CardRequest";
import Layout from "components/layout";
import { useEffect, useState } from "react";
import { requestMessage } from "utils/constants";
import { ToastContainer } from "react-toastify";
import { notifySuccess, notifyError } from "utils/helper";
import {
  getPendingRequestAPI,
  getPendingRequestAPIAffiliate_Admin,
  responsePendingRequest,
} from "services/requestPending";
import Loading from "components/Loading";
import { DataIcon } from "assets/icons/Icons";
import "bootstrap/dist/css/bootstrap.css";
import "styles/engage-tool.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const UserRequest = () => {
  const authInfo = useSelector((state) => state.auth);

  const [listPending, setListPending] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRequest, setIsRequest] = useState(false);

  const getListPending = async () => {
    try {
      const list = [];
      setLoading(true);

      let response = null;
      if (authInfo?.role === "affiliate_admin") {
        response = await getPendingRequestAPI();
      }

      if (authInfo?.role === "system_admin") {
        response = await getPendingRequestAPI();
      }
      // const response = await getPendingRequestAPI();

      if (response && response.data) {
        for (const property in response.data) {
          if (!Array.isArray(response.data[property]))
            list.push(response.data[property]);
        }
        setListPending([...list]);
      }

      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
      setIsRequest(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (affiliates) => {
    const data = { accept: [affiliates] };
    setLoading(true);

    try {
      const response = await responsePendingRequest({ data });
      if (response) {
        notifySuccess(requestMessage.APPROVE_SUCCESS_MESSAGE);
        getListPending();
      }
      setLoading(false);
    } catch (error) {
      notifyError(requestMessage.APPROVE_ERROR_MESSAGE);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeny = async (affiliates) => {
    const data = { deny: [affiliates] };
    setLoading(true);

    try {
      const response = await responsePendingRequest({ data });
      if (response) {
        notifySuccess(requestMessage.DENY_SUCCESS_MESSAGE);
        getListPending();
      }
      setLoading(false);
    } catch (error) {
      notifyError(requestMessage.DENY_ERROR_MESSAGE);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListPending();
  }, []);

  useEffect(() => {
    if (listPending.length > 0) {
      setIsRequest(true);
    } else {
      setIsRequest(false);
    }
  }, [listPending.length]);
  console.log(">list", listPending);

  return (
    <div className="container-fluid listBox-Admin">
      <div className="row border-b-2 border-blue-20">
        <Link
          to={`${
            authInfo?.role === "system_admin"
              ? "/admin/user-request"
              : "/affiliate-admin/user-request"
          }`}
          className="text-center text-white text-3xl font-bold no-underline"
        >
          <h2 className="linkTitle">
            <b>User Request{` (${listPending?.length})`}</b>
          </h2>
        </Link>
      </div>
      <div className="row py-4">
        <div className="col-md-12 d-flex justify-content-center">
          {!isRequest ? (
            <>
              <ToastContainer className="mt-1" />
              <div className="flex justify-center items-center w-full h-full">
                <div className="text-sliver-10 flex flex-col justify-center items-center">
                  <DataIcon size={64} />
                  <p className="text-2xl">No pending requests</p>
                </div>
                <Loading isVisible={loading} />
              </div>
            </>
          ) : (
            <>
              <ToastContainer className="mt-1" />
              <div className="flex flex-wrap justify-center gap-4">
                {listPending.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full md:w-[50%] lg:w-[30%] min-w-[320px]"
                    >
                      <CardRequest
                        data={item}
                        handleApprove={() => handleApprove(item)}
                        handleDeny={() => handleDeny(item)}
                      />
                    </div>
                  );
                })}
              </div>
              <Loading isVisible={loading} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRequest;
