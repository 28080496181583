import {
  SignOutIcon,
  UserIcon,
  HomeIcon,
  AdminIcon,
  MessageIcon,
} from "assets/icons/Icons";
import logo from "assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authActions from "redux/actions/auth";
import "bootstrap/dist/css/bootstrap.css";
import { Nav, Navbar, NavDropdown, Modal } from "react-bootstrap";
import "styles/engage-tool.css";
import Popup from 'reactjs-popup';
import { FaQuestionCircle } from "react-icons/fa";
import ModalPopup from './modal_popup'; 
import PopupHelp from "components/PopupHelp";

const NAVbar = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state.auth);
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(
      authActions.logout({
        isLoggedIn: false,
        role: null,
      })
    );
    navigate("/login");
  };

  useEffect(() => {
      console.log('modalOpen = ', modalOpen);
  }, [modalOpen])
  
  function idleLogout() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer; // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.ontouchmove = resetTimer; // required by some devices
    window.onclick = resetTimer; // catches touchpad clicks as well
    window.onkeydown = resetTimer;
    window.addEventListener("scroll", resetTimer, true); // improved; see comments

    function yourFunction() {
      handleLogout();
    }

    function resetTimer() {
      clearTimeout(t);
      t = setTimeout(yourFunction, 900000); // time is in milliseconds
    }
  }
  idleLogout();

  //const Modal = () => (  <Popup trigger={<FaQuestionCircle style={{color: 'white', fontSize: '20px'}}/>} modal>    <span> Modal content </span>  </Popup>);
  function isShowPopup (status)  {  
    this.setState({ showModalPopup: status });  
  }; 
  
  return (
    <div>
    <Navbar id="topBar" expand="lg" variant="dark">
      <Navbar.Brand>
        <img src={logo} width="200" />
      </Navbar.Brand>
      
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">

        <Nav className={(modalOpen) ? 'is-disabled' : ''}>
          <Nav.Link bg="theNav" href="/dashboard">
            <b>HOME</b>
          </Nav.Link>

          {authInfo.role === "affiliate_admin" && (
            <Nav.Link href="/affiliate-admin">
              <div>
                <b>ADMIN</b>
              </div>
            </Nav.Link>
          )}
          {authInfo.role === "system_admin" && (
            <Nav.Link href="/admin">
              <div>
                <b>ADMIN</b>
              </div>
            </Nav.Link>
          )}
          <Nav.Link href="/my-profile">
            <b>MY PROFILE</b>
          </Nav.Link>

         <Nav.Link onClick={() => {
                    setModalOpen(true);
                }}>
            <b>HELP</b>
          </Nav.Link>    
          
          <Nav.Link key="signout" onClick={handleLogout}>
            <div id="signout">
              <b>SIGN OUT</b> - Signed In As{" "}
              {authInfo.name ? authInfo.name : ""}
            </div>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    
    {modalOpen && <PopupHelp setOpenModal={setModalOpen} />}
    </div>
  );
  
  
};

export default NAVbar;
